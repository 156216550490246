//.grid-container {
//    .paging.paging-fixed {
//        position: initial !important;
//    }
//}
.button-hover:hover{
    display: block;
    background-color: red;
    color: red;
}
@media (max-width: 580px) {
    .W06F0007-ActionToolbar{
        min-height: 101px !important;
    }
  }


